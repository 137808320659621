import React, {Component} from 'react';
import Icons from './Icons.jsx';
import Arrows from './Arrows.jsx';
import BoardEvent from './BoardEvent.jsx';
import {forwardsVideo} from '../entities/ForwardsVideo.jsx';
import {backwardsVideo} from '../entities/BackwardsVideo.jsx';
import {Events} from '../entities/events/';
import {responsiveness} from '../helpers/responsiveness.js';
import {eventHandler} from '../entities/events/handler.js';


export default class FreeMovement extends Component {
  player = null;
  bPlayer = null;
  events = [];
  playing=false;

  constructor(props) {
    super(props);
    this.state = {
      isAutoPlay: false,
      shownBoardEvent: null,
      direction: 'forwards',
      allowPlaying: true,

    };

  };

  adjustDimensions = () => {

    let innerWrapperEl = document.getElementsByClassName('inner-wrapper')[0];
    let forwardsEl = document.getElementById('forwards');

    responsiveness.adjustWidthTo([forwardsEl, innerWrapperEl], this.props.videoWidth);
    responsiveness.adjustHeightTo([forwardsEl, innerWrapperEl], this.props.videoHeight);

    let backwardsEl = document.getElementById('backwards');
    responsiveness.adjustWidthTo([backwardsEl, innerWrapperEl], this.props.videoWidth);
    responsiveness.adjustHeightTo([backwardsEl, innerWrapperEl], this.props.videoHeight);
  };


  onTimeUpdate = (time) => {
    if(this.playing)
    this.events.onTimeUpdate(time,this.state.direction);
    eventHandler.onTimeUpdate(time, this.state.isAutoPlay);
  };

  showBoardEvent = evt => {
    this.player.pause();
    this.playing = false;
    this.setState({
      shownBoardEvent: evt,
      allowPlaying: false
  });

  };

  hideBoardEvent = () => {
    this.player.pause();
    this.bPlayer.pause();
    this.setState({shownBoardEvent: null, allowPlaying: true});
    this.events.hideBoardEvent();

  };

  getEventsCallbacks = () => {
    return {
      showBoardEvent: this.showBoardEvent,
      hideBoardEvent: this.hideBoardEvent,
      pause: () => this.player.pause(),
      play: () => this.player.play(),
      backwardsPause: () => this.bPlayer.pause(),
      backwardsPlay: () => this.bPlayer.play(),
      allowPlaying: () => this.setState({allowPlaying: false})
    }
  };

  onIconClicked = e => {
    let icon = e.target;
    this.player.pause();
    this.bPlayer.pause();
    if(icon.next === 'noAction'){
      return
    }
    if(icon.isBack){
      eventHandler.onClick(icon, backwardsVideo.getCurrData());
      this.setState({allowPlaying: true,direction:"backwards"})
    }else{
      eventHandler.onClick(icon, forwardsVideo.getCurrData());
      this.setState({allowPlaying: true,direction:"forwards"});
    }
  };

  move = (direction) => {
    this.stopBlinking();
    if(!this.state.allowPlaying || this.playing) return;
    if(this.state.direction !== direction){
      this.events.restartBoardEvent();
      this.setState({direction});
    }
    this.playing = true;
    direction === 'forwards' ? this.player.play() : this.bPlayer.play()
};

  stopMoving = () => {
    this.stopBlinking();
    window.setInterval(this.blinkArrow, 5000);
    this.playing = false;
    if(this.state.direction === 'forwards'){
      let ct = this.player.duration() - this.player.currentTime();
      this.bPlayer.currentTime(ct)
    }else{
      let ct = this.bPlayer.duration() - this.bPlayer.currentTime()
      this.player.currentTime(ct)
    }

    this.player.pause();
    this.bPlayer.pause();
}

blinkArrow = () => {
  document.querySelector('.arrow.up').classList.add('animated');
  document.querySelector('.arrow.down').classList.add('animated');

}

stopBlinking = () => {
  document.querySelector('.arrow.up').classList.remove('animated');
  document.querySelector('.arrow.down').classList.remove('animated');
  window.clearInterval(this.inactivityInterval);
}
  render() {

    return (
      <div className="video-wrapper">
       <div className="inner-wrapper">
         <BoardEvent evt={this.state.shownBoardEvent} proceed={this.hideBoardEvent} />
         <Icons onIconClicked={this.onIconClicked} width={this.props.videoWidth} height={this.props.videoHeight}/>
         {forwardsVideo.render(this.state.direction)}
         {backwardsVideo.render(this.state.direction)}
       </div>
       <Arrows move={this.move} stopMoving={this.stopMoving} playing={this.playing}></Arrows>

     </div>
   )
  };

  componentDidMount() {
    let self = this;
    let options = {
      ...this.props,
      onTimeUpdate: self.onTimeUpdate,
      onLoadedData: self.onLoadedData,
      isAutoPlay: false
    };
    this.player = forwardsVideo.init(this.props.project.video, options);
    this.bPlayer = backwardsVideo.init(this.props.project.backVideo, options);
    this.inactivityInterval = window.setInterval(this.blinkArrow, 5000);
    responsiveness.calcArrowsPosition(document.getElementById('3Dstore_html5_api'));
    this.adjustDimensions();
    this.events = new Events(this.props.project, this.getEventsCallbacks(),this.props.jumpToTime);

  }
}
