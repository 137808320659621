import { iconUtil } from "../../helpers/iconUtil.js";

export const stopEvents = {
  events: [],
  eventsOnCanvas: [],
  adminVideoWidth: null,
  adminVideoHeight: null,

  addEvent: function (event, adminVideoWidth, adminVideoHeight) {
    this.events.push(event);
    this.adminVideoWidth = adminVideoWidth;
    this.adminVideoHeight = adminVideoHeight;
  },

  update: function (currentTime, videoLoopEvent = false, setvideoLoopEvent) {
    for (let i = 0; i < this.events.length; i++) {
      let currEvt = this.events[i];
      let startTime = parseFloat(currEvt.startTime);
      if (currentTime > startTime && currentTime - window._THRESHOLD < startTime) {
        if (!this.isEventOnCanvas(currEvt))
          this.putEventOnCanvas(currEvt, setvideoLoopEvent);
      } else {
        if (this.isEventOnCanvas(currEvt) && !videoLoopEvent) {
          this.removeEventFromCanvas(currEvt, setvideoLoopEvent);
        }
      }
    }
  },

  isEventOnCanvas: function (evt) {
    return this.eventsOnCanvas.some(
      (canvasEvent) => canvasEvent.eventID === evt.id
    );
  },

  putEventOnCanvas: function (evt, setvideoLoopEvent) {
    evt.subevents.forEach((subevt) => {
      let icon = iconUtil.createIcon(
        subevt,
        this.adminVideoWidth,
        this.adminVideoHeight
      );
      if (subevt.next === "loop") {
        setvideoLoopEvent(true);
        return;
      }

      this.eventsOnCanvas.push(icon);
      window.pubSub.emit("drawIcon", icon);
    });
  },

  removeEventFromCanvas: function (evt, setvideoLoopEvent) {
    evt.subevents.forEach((subevt) => {
      if (subevt.next === "loop") {
        setvideoLoopEvent(false)
        return;
      }
      let evtIdx = this.eventsOnCanvas.findIndex(
        (canvasEvent) => canvasEvent.eventID === evt.id
      );
      let canvasEvent = this.eventsOnCanvas[evtIdx];
      window.pubSub.emit("eraseIcon", canvasEvent);

      this.eventsOnCanvas.splice(evtIdx, 1);
    });
  },
  shouldLoop: function (currentTime) {
    let shoudLoop = false;
    const loopEvent = this.events.filter(evt => currentTime > evt.startTime && currentTime - window._THRESHOLD < evt.startTime)[0]
    loopEvent.subevents.forEach((subevt) => {
      if (subevt.next === "loop") {
        shoudLoop = subevt;
        return;
      }
    })
    return shoudLoop;
  },
  hasStopEvent: function () {
    return this.eventsOnCanvas.length;
  },
};
