import React from 'react';
import {api} from '../api';
import M from 'materialize-css';

export default function ProblemReport (props) {
  let sendReport = () => {
    let msg = null;
    let msgEl =  document.getElementById('issue');
    let userid = window.localStorage.getItem('uid');
    let ts =props.getTS();
    let {time, type} = props.getVideoData();

    if (msgEl.value) {
      msg = {
        text: msgEl.value,
        ts,
        videoTime: time,
        videoType: type
      };
      msg = JSON.stringify(msg);
      api.sendReport({userid, msg})
        .then(() => {
          msgEl.value = '';
          M.toast({html: 'Reported successfully!', classes: 'green', displayLength: 3000});
        })
        .catch(err =>  M.toast({html: 'Reporting failed.', classes: 'red', displayLength: 3000}));
    }
  }
  return (
    <div className="problem-report">
      <textarea name="issue" id="issue" placeholder="Report a problem"></textarea>
      <button className="btn red lighten-2 white-text" onClick={sendReport}>Send</button>
    </div>
  )
}
