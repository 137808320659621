import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style/index.css';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import PubSub from './helpers/pub-sub.js';

M.AutoInit();

window.pubSub = new PubSub();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
