import React, {Component} from 'react';
import Store from './components/Store.jsx';

class App extends Component {
  render () {
    return (
      <div className="app">
        <Store />
      </div>
    );
  };
}

export default App;
