import React, { Component } from "react";
import { fabric } from "fabric";

export default class Icons extends Component {
  constructor(props) {
    super(props);
    this.iconWrapperRef = React.createRef();
  }

  drawIcon = (iconData) => {
    fabric.Image.fromURL(iconData.path, (oImg) => {
      oImg.id = iconData.id;
      oImg.left = iconData.left;
      oImg.top = iconData.top;
      oImg.scaleX = iconData.scaleX;
      oImg.scaleY = iconData.scaleY;
      oImg.angle = iconData.angle;
      oImg.subevtID = iconData.subevtID;
      oImg.name = iconData.subevtName;
      oImg.subeventLabelVisibility = iconData.subeventLabelVisibility;
      oImg.next = iconData.next;
      oImg.isExit = iconData.isExit;
      oImg.isBack = iconData.isBack;
      oImg.eventID = iconData.eventID;
      oImg.directLink = iconData.directLink;
      oImg.continueAt = iconData.continueAt;
      oImg.introStart = iconData.introStart;
      oImg.introEnd = iconData.introEnd;
      oImg.hoverCursor = "pointer";
      oImg.selectable = false;
      oImg.on("mouseover", this.setShadow);
      oImg.on("mouseout", this.removeShadow);
      oImg.on("mousedown", this.props.onIconClicked);
      this.canvas.add(oImg);
      this.setLabel(iconData, oImg);
    });
    this.canvas.renderAll();
  };


  eraseIcon = (icon) => {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.id == icon.id) {
        this.removeLabel(obj);
        this.canvas.remove(obj);
      }
    });
  };

  setShadow = (e) => {
    let obj = this.canvas.getObjects().find((img) => img.id === e.target.id);
    obj.setShadow({
      blur: 100,
      color: "white",
      offsetX: 10,
      offsetY: 10,
      opacity: 0.5,
    });
    this.canvas.renderAll();
  };

  removeShadow = (e) => {
    let obj = this.canvas.getObjects().find((img) => {
      if (img && e.target) return img.id === e.target.id;
    });
    if (obj) {
      obj.setShadow(null);
      this.canvas.renderAll();
    }
  };

  setLabel = (iconData, oImg) => {
    if (!iconData.subevtLabel) return;
    let canvasContainer =
      document.getElementsByClassName("canvas-container")[0];
    let label = document.createElement("label");
    label.id = "event-label-" + iconData.id;
    label.classList.add("event-label");
    label.style.top = iconData.top - 30 + "px";
    label.style.left = iconData.left + 20 + "px";
    label.innerText = iconData.subevtLabel;
    canvasContainer.appendChild(label);
    if (iconData.subeventLabelVisibility === "onHover") {
      label.style.visibility = "hidden";
      oImg.on("mouseover", () => {
        label.style.visibility = "visible";
      });
      oImg.on("mouseout", () => {
        label.style.visibility = "hidden";
      });
    }
  };

  removeLabel = (obj) => {
    let el = document.getElementById("event-label-" + obj.id);
    if (!el) return;
    el.style.display = "none";
    el.parentNode.removeChild(el);
  };

  hideAllLabels = () => {
    let elems = document.querySelectorAll(".event-label");
    for (let i = 0; i < elems.length; i++) {
      elems[i].parentNode.removeChild(elems[i]);
    }
  };

  render() {
    return (
      <div ref={this.iconWrapperRef} className="icons">
        <canvas
          id="icons-canvas"
          width={this.props.width}
          height={this.props.height}
        ></canvas>
      </div>
    );
  }

  componentDidMount() {
    this.canvas = new fabric.Canvas("icons-canvas", {});
    window.pubSub.on("drawIcon", (icon) => this.drawIcon(icon));
    window.pubSub.on("eraseIcon", (icon) => this.eraseIcon(icon));
  }

  componentWillUnmount() {
    window.pubSub.off("drawIcon");
    window.pubSub.off("eraseIcon");
  }
}
