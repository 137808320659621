import { arrowEvents } from "./arrowEvents";
import { autoRedirects } from "./autoRedirects";
import { boardEvents } from "./boardEvents";
import { stopEvents } from "./stopEvents";

export class Events {
  constructor(project, callbacks, jumpToTime) {
    this.jumpToTime = jumpToTime;
    this.callbacks = callbacks;
    this.adminVideoWidth = project.videoWidth;
    this.adminVideoHeight = project.videoHeight;
    project.allEvents.forEach((event) => this.createEvent(event));
  }

  createEvent = (event) => {
    switch (event.type) {
      case "arrow":
        arrowEvents.addEvent(
          event,
          this.adminVideoWidth,
          this.adminVideoHeight
        );
        break;
      case "autoRedirect":
        autoRedirects.addEvent(event);
        break;
      case "board":
        boardEvents.addEvent(event);
        if (event.instructionsIntro && this.jumpToTime == null) {
          this.callbacks.showBoardEvent(event);
        }
        break;
      case "stop":
        stopEvents.addEvent(event, this.adminVideoWidth, this.adminVideoHeight);
        break;
    }
  };

  hideBoardEvent = () => {
    boardEvents.hide();
  };

  restartBoardEvent = () => {
    boardEvents.restart();
  };

  loopvideo = (currentTime, currentLoopSubEvt) => {
    if (currentTime >= currentLoopSubEvt.endTime) {
      this.callbacks.setLoops()
      this.callbacks.setPlayerTime(currentLoopSubEvt.startTime);
    }
  };

  onTimeUpdate = (currentTime, direction, videoLoopEvent, currentLoopSubEvt) => {
    if (autoRedirects.isAutoRedirect(currentTime)) {
      autoRedirects.redirect();
      return;
    }

    if (boardEvents.shouldShow(currentTime, direction)) {
      this.callbacks.showBoardEvent(boardEvents.showEvent());
      return;
    }
    stopEvents.update(
      currentTime,
      videoLoopEvent,
      this.callbacks.setvideoLoopEvent
    );

    if (videoLoopEvent) this.loopvideo(currentTime, currentLoopSubEvt);

    if (stopEvents.hasStopEvent() && !videoLoopEvent) {
      const loopEvent = stopEvents.shouldLoop(currentTime)
      if (!loopEvent) {
        direction === "forwards"
          ? this.callbacks.pause()
          : this.callbacks.backwardsPause();
        this.callbacks.allowPlaying();
      } else {
        this.callbacks.setCurrentLoopSubEvt(loopEvent)
      }

    }



    arrowEvents.update(currentTime);
  };
}
