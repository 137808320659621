import axios from './config.js';

function getSessionParams () {
  let user = JSON.parse(localStorage.getItem('user'));
  return user ? {
    user_key: user.user_key,
    userid: user.userid
  } : null;
};

export const api = {
  fetchStore (params) {
    return axios({
      url: 'projects',
      params
    });
  },

  loadCell (data) {
    return axios({
      url: 'loadCell',
      method: 'POST',
      data
    });
  },

  updatePath (data) {
    return axios({
      url: 'updatePath',
      method: 'POST',
      data
    });
  },

  endTest (params) {
    return axios({
      url: 'endTest',
      params
    });
  },

  sendReport (data) {
    return axios({
      url: 'report',
      method: 'POST',
      data
    });
  }
}
