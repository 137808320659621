import { getTS } from '../../helpers/utils.js';
import { api } from '../../api/';
import { UserStatistics } from '../UserStatistics.js'

export const eventHandler = {
  intro: null,
  isAutoPlay: null,

  onClick: function (event, videoData) {
    if (event.isExit) {
      this.handleExit(event, videoData);
      return;
    };

    switch (event.next) {
      case 'dl':
        this.handleDL(event, videoData);
        break;
      case 'intro+dl':
        this.handleIntroDL(event, videoData);
        break;
      case 'continue':
        this.handleContinue(event, videoData);
        break;
      default:
        break;
    }

  },

  handleDL: function (event, videoData) {
    this.updatePath(event, videoData)
      .then(() => window.location.href = this.getRedirectLink(event));
  },

  handleIntroDL: function (event, videoData) {
    this.updatePath(event, videoData)
      .then(() => this.intro.canRedirect = true);
    let redirectLink = this.getRedirectLink(event);
    this.intro = {
      end: event.introEnd,
      redirectLink,
      canRedirect: false,
    }
    window.pubSub.emit('setPlayerTime', event.introStart);
    if (this.isAutoPlay) window.pubSub.emit('playVideo');

  },

  handleExit: function (event, videoData) {
    let path = JSON.stringify(event, videoData)
    window.location.href = `https://api.store.eyeseeshopping.com/app/endTest?userid=${window.localStorage.getItem('uid')}&path=${path}`;
  },

  handleContinue: function (event, videoData) {
    window.pubSub.emit('pauseVideo');
    window.pubSub.emit('pauseBVideo');
    let bct = videoData.videoDuration - Number(event.continueAt);
    let fct = Number(event.continueAt);
    window.pubSub.emit('setBPlayerTime', bct);
    window.pubSub.emit('setPlayerTime', fct);
    this.updatePath(event, videoData)
      .then(() => {
        if (event.isBack) {
          if (this.isAutoPlay) window.pubSub.emit('playBVideo');
        } else {
          if (this.isAutoPlay) window.pubSub.emit('playVideo');
        }

      });
  },

  onTimeUpdate: function (time, isAutoPlay) {
    this.isAutoPlay = isAutoPlay;
    if (this.intro && time >= this.intro.end && this.intro.canRedirect)
      window.location.href = this.intro.redirectLink;
  },

  getRedirectLink: function (event) {
    return `${event.directLink}&userid=${window.localStorage.getItem('uid')}`;
  },

  updatePath: function (event, videoData) {
    let params = this.getUpdatePathParams(event, videoData);
    return api.updatePath(params)
      .then(response => {
        UserStatistics.reset();
        return Promise.resolve(response.data);
      })
      .catch(err => Promise.resolve(true))
      .finally(() => Promise.resolve(true));
  },

  getUpdatePathParams: function (event, videoData) {
    return {
      userid: window.localStorage.getItem('uid'),
      path: JSON.stringify(this.getPath(event, videoData))
    }
  },

  getPath: function (event, videoData) {
    return {
      name: event.name,
      type: event.next,
      videoTime: videoData.time,
      videoType: videoData.type,
      ts: getTS(),
      clicks: UserStatistics.getClicks,
      loops: videoData.loops
    };
  }
}
