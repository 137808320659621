import React, {useEffect, useState} from 'react';
import {responsiveness} from '../helpers/responsiveness.js';

export default function BoardEvent({evt, proceed}) {
  let [evtStyle, setEvtStyle] = useState({visibility: 'hidden'});

  useEffect(() => {
    let dimensions = responsiveness.calcBoardEvtDimensions();
    if (evt) {
      let videoOpacityLevel = evt.opacityVideoLevel / 100;
      let background = `rgba(0,0,0,${videoOpacityLevel})`;
      setEvtStyle({display: 'flex', width: dimensions.width, height: dimensions.height, background:background});
      document.getElementsByClassName('board-evt-body')[0].style.height = dimensions.boardEvtBodyHeight;
      document.getElementsByClassName('board-evt-content')[0].style.opacity = evt.opacityLevel + "%";
    } else {
      setEvtStyle({display: 'none'});
    }
  }, [evt]);

  let createMarkup = () => {
    return evt ? {__html: evt.text} : {__html: ''};
  };

  return (
    <div className="board-evt" style={evtStyle}>
      <div className="board-evt-content card">
        <div className="board-evt-title">
          <h6>
            {evt && evt.name}
          </h6>
        </div>

        <div className="board-evt-body" dangerouslySetInnerHTML={createMarkup()}>
        </div>

        <div className="board-evt-footer">
          <button className="btn blue white-text" onClick={proceed}>OK</button>
        </div>
      </div>
    </div>
  );
}
