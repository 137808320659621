import React, { Component } from "react";
import BoardEvent from "./BoardEvent.jsx";
import Icons from "./Icons.jsx";
import { forwardsVideo } from "../entities/ForwardsVideo.jsx";
import { backwardsVideo } from "../entities/BackwardsVideo.jsx";
import { Events } from "../entities/events/";
import { responsiveness } from "../helpers/responsiveness.js";
import { eventHandler } from "../entities/events/handler.js";

export default class AutomaticMovement extends Component {
  player = null;
  bPlayer = null;
  events = [];

  constructor(props) {
    super(props);
    this.state = {
      isAutoPlay: true,
      shownBoardEvent: null,
      direction: "forwards",
      allowPlaying: true,
      shouldEventsUpdate: true,
      videoLoopEvent: false,
      currentLoopSubEvt: null,
      loops: 0
    };
  }

  adjustDimensions = () => {
    let innerWrapperEl = document.getElementsByClassName("inner-wrapper")[0];
    let forwardsEl = document.getElementById("forwards");
    responsiveness.adjustWidthTo(
      [forwardsEl, innerWrapperEl],
      this.props.videoWidth
    );
    responsiveness.adjustHeightTo(
      [forwardsEl, innerWrapperEl],
      this.props.videoHeight
    );

    let backwardsEl = document.getElementById("backwards");
    responsiveness.adjustWidthTo(
      [backwardsEl, innerWrapperEl],
      this.props.videoWidth
    );
    responsiveness.adjustHeightTo(
      [backwardsEl, innerWrapperEl],
      this.props.videoHeight
    );
  };

  onTimeUpdate = (time) => {
    if (this.state.shouldEventsUpdate) {
      this.events.onTimeUpdate(
        time,
        this.state.direction,
        this.state.videoLoopEvent,
        this.state.currentLoopSubEvt
      );
    }
    eventHandler.onTimeUpdate(time, this.state.isAutoPlay);
    if (!this.state.allowPlaying) {
      if (this.state.direction === "forwards") {
        let ct = this.player.duration() - this.player.currentTime();
        let bPlayerCt = this.bPlayer.currentTime();
        if (ct != bPlayerCt) {
          this.bPlayer.currentTime(ct);
          this.setState({
            shouldEventsUpdate: false,
          });
        }
      } else {
        let bPlayerCt = this.bPlayer.duration() - this.bPlayer.currentTime();
        let ct = this.player.currentTime();
        if (ct != bPlayerCt) {
          this.player.currentTime(bPlayerCt);
          this.setState({
            shouldEventsUpdate: false,
          });
        }
      }
      this.bPlayer.pause();
      this.player.pause();
    }
  };

  showBoardEvent = (evt) => {
    this.setState({ shownBoardEvent: evt, allowPlaying: false });
    this.player.pause();
  };

  hideBoardEvent = () => {
    this.player.play();
    this.setState({
      shownBoardEvent: null,
      allowPlaying: true,
      shouldEventsUpdate: true,
    });
    this.events.hideBoardEvent();
  };

  getEventsCallbacks = () => {
    return {
      showBoardEvent: this.showBoardEvent,
      hideBoardEvent: this.hideBoardEvent,
      pause: () => this.player.pause(),
      play: () => this.player.play(),
      setPlayerTime: (t) => this.player.currentTime(t),
      backwardsPause: () => this.bPlayer.pause(),
      backwardsPlay: () => this.bPlayer.play(),
      setBPlayerTime: (t) => this.bPlayer.currentTime(t),
      allowPlaying: (value = false) => this.setState({ allowPlaying: value }),
      setvideoLoopEvent: (value) => this.setState({ videoLoopEvent: value }),
      setCurrentLoopSubEvt: (evt) => this.setState({ currentLoopSubEvt: evt }),
      setLoops: () => this.setState({ loops: this.state.loops + 1 })
    };
  };

  onIconClicked = (e) => {
    let icon = e.target;
    if (icon.next === "noAction") {
      return;
    }

    if (this.state.videoLoopEvent)
      this.setState({ videoLoopEvent: false })

    if (icon.isBack) {
      this.player.pause();
      this.bPlayer.pause();
      const pathData = { ...backwardsVideo.getCurrData(), loops: this.state.loops }
      eventHandler.onClick(icon, pathData);

      let backwardsVideoData = backwardsVideo.getCurrData();
      let ct = (
        backwardsVideoData.videoDuration - Number(icon.continueAt)
      ).toFixed(2);
      this.timeout = setInterval(() => {
        let backwardsVideoData = backwardsVideo.getCurrData();
        let bVideoCurrTime = backwardsVideoData.time.toFixed(2);

        if (ct === bVideoCurrTime) {
          //check if back video is in right position
          this.setState({
            allowPlaying: true,
            direction: "backwards",
            shouldEventsUpdate: true,
            videoLoopEvent: false,
            loops: 0
          });
          clearTimeout(this.timeout);
        }
      }, 50);
    } else {
      this.player.pause();
      this.bPlayer.pause();
      const pathData = { ...forwardsVideo.getCurrData(), loops: this.state.loops }
      eventHandler.onClick(icon, pathData);

      let videoData = forwardsVideo.getCurrData();
      let ct = Number(icon.continueAt).toFixed(2);

      this.timeout = setInterval(() => {
        let videoData = forwardsVideo.getCurrData();
        let videoCurrTime = videoData.time.toFixed(2);

        if (ct === videoCurrTime) {
          //check if fwd video is in right position
          this.setState({
            allowPlaying: true,
            direction: "forwards",
            shouldEventsUpdate: true,
            videoLoopEvent: false,
            loops: 0
          });
          clearTimeout(this.timeout);
        }
      }, 50);
    }
  };

  render() {
    return (
      <div className="video-wrapper">
        <div className="inner-wrapper">
          <BoardEvent
            evt={this.state.shownBoardEvent}
            proceed={this.hideBoardEvent}
          />
          <Icons
            onIconClicked={this.onIconClicked}
            width={this.props.videoWidth}
            height={this.props.videoHeight}
          />
          {forwardsVideo.render(this.state.direction)}
          {backwardsVideo.render(this.state.direction)}
        </div>
      </div>
    );
  }

  componentDidMount() {
    let self = this;
    let options = {
      ...this.props,
      onTimeUpdate: self.onTimeUpdate,
      isAutoPlay: true,
    };
    this.player = forwardsVideo.init(this.props.project.video, options);
    this.bPlayer = backwardsVideo.init(this.props.project.backVideo, options);
    this.adjustDimensions();
    this.events = new Events(
      this.props.project,
      this.getEventsCallbacks(),
      this.props.jumpToTime,

    );
  }
}
