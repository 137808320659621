import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';


export const backwardsVideo = {
  bPlayer: null,
  jumpToTime: null,
  notifyTimeUpdate: null,

  init: function (src, additionalOptions) {
    let storeEl= document.getElementById('3Dstore-back');
    let options = {
      controls: false,
      width: additionalOptions.videoWidth,
      sources: [{ src }]
    };
    this.jumpToTime = additionalOptions.jumpToTime;
    this.notifyTimeUpdate = additionalOptions.onTimeUpdate;
    this.isAutoPlay = additionalOptions.isAutoPlay;

    var self = this;
      this.bPlayer = videojs(storeEl, options, () => {
      this.bPlayer.on('loadeddata', () => self.onLoadedData.call(self));
      this.bPlayer.on('timeupdate', () => self.onTimeUpdate.call(self));
      window.pubSub.on('setBPlayerTime', t => this.bPlayer.currentTime(t));
      window.pubSub.on('playBVideo', () => this.bPlayer.play());
      window.pubSub.on('bVideoCurrTime', () => this.bPlayer.currentTime());
    });

    return this.bPlayer;
  },

  getCurrData: function () {
    return {
      type: 'back',
      time: this.bPlayer.currentTime(),
      videoDuration: this.bPlayer.duration()
    }
  },
  onLoadedData: function () {
    if (this.jumpToTime) this.bPlayer.currentTime(parseFloat(this.jumpToTime));

  },
  onTimeUpdate: function () {
    this.notifyTimeUpdate(this.bPlayer.duration() - this.bPlayer.currentTime());
  },


  render: function (direction) {
    const visibility = direction === 'backwards' ? 'visible' : 'hidden';
    return (
      <div style={{visibility}} id="backwards">
        <div data-vjs-player>
          <video className="video video-js vjs-16-9" id='3Dstore-back' muted></video>
        </div>
      </div>
    );
  },
}
