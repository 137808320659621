export const boardEvents = {
  events: [],
  eventShownIdx: -1,

  addEvent: function (event) {
    if(event.instructionsIntro){
      event.alredyShowed = true
    }
    this.events.push(event);
  },

  shouldShow: function (currentTime,direction) {
    if (this.eventShownIdx > -1 || direction !== "forwards") return false; // time threshold is too small, so we need to prevent program to show event after it is being shown
    for (let i = 0; i < this.events.length; i++) {
      let startTime = parseFloat(this.events[i].startTime);
      if (currentTime > startTime && currentTime - window._THRESHOLD < startTime && !this.events[i].alredyShowed) {

        this.events[i].alredyShowed = true;
        this.eventShownIdx = i;
        return true;
      }

    }
    return false;
  },

  showEvent: function () {
    return this.events[this.eventShownIdx];
  },

  hide: function () {
    this.eventShownIdx = -1;
    return null;
  },

  restart: function () {
    for (let evt of this.events){
      if (evt.alredyShowed){
        evt.alredyShowed = false;
      }
    }

  }
}
