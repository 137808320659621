import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const forwardsVideo = {
  player: null,
  jumpToTime: null,
  notifyTimeUpdate: null,

  init: function (src, additionalOptions) {
    let storeEl= document.getElementById('3Dstore');
    let options = {
      controls: false,
      width: additionalOptions.videoWidth,
      sources: [{ src }]
    };
    this.jumpToTime = additionalOptions.jumpToTime;
    this.notifyTimeUpdate = additionalOptions.onTimeUpdate;
    this.isAutoPlay = additionalOptions.isAutoPlay;


    var self = this;
    this.player = videojs(storeEl, options, () => {
      this.player.on('loadeddata', () => self.onLoadedData.call(self));
      this.player.on('timeupdate', () => self.onTimeUpdate.call(self));
      window.pubSub.on('setPlayerTime', t => this.player.currentTime(t));
      window.pubSub.on('playVideo', () => this.player.play());
      window.pubSub.on('pauseVideo', () => this.player.pause());

    });

    return this.player;
  },

  getCurrData: function () {
    return {
      type: 'front',
      time: this.player.currentTime(),
      videoDuration: this.player.duration()
    }
  },

  onLoadedData: function () {
    if (this.jumpToTime) this.player.currentTime(parseFloat(this.jumpToTime));
    if (this.isAutoPlay)
      this.player.play();
  },

  onTimeUpdate: function () {
    this.notifyTimeUpdate(this.player.currentTime());
  },

  render: function (direction) {
    const visibility = direction === 'forwards' ? 'visible' : 'hidden';
    return (
      <div style={{visibility}} id="forwards">
        <div data-vjs-player>
          <video className="video video-js vjs-16-9" id='3Dstore' muted></video>
        </div>
      </div>
    );
  },
}
