export const autoRedirects = {
  redirects: [],
  redirectIndex: -1,

  addEvent: function (event) {
    this.redirects.push(event);
  },

  isAutoRedirect: function (currentTime) {
    if (this.redirectIndex > -1) return false; // time threshold is too small, so we need to prevent program to redirect twice
    for (let i = 0; i < this.redirects.length; i++) {
      let startTime = parseFloat(this.redirects[i].startTime);
      if (currentTime > startTime && currentTime - window._THRESHOLD < startTime) {
        this.redirectIndex = i;
        return true;
      }
    }
    return false;
  },

  redirect: function () {
  //  let videoData = this.getVideoData();
    let evt = this.redirects[this.redirectIndex];
    let hasQueryParam = evt.autoRedirectLink.indexOf('?') >= 0;
    let paramDelimiter = hasQueryParam ? '&' : '?';
    let redirectLink =`${evt.autoRedirectLink}${paramDelimiter}userid=${window.localStorage.getItem('uid')}`;
    window.location.replace(redirectLink);
    // this.apiUpdatePath(evt, videoData).then(() => window.location.replace(redirectLink));
  },


}
