import React, {useState, useEffect} from 'react';
import up from '../assets/images/up-normal.png';
import right from '../assets/images/right-normal.png';
import down from '../assets/images/down-normal.png';
import left from '../assets/images/left-normal.png';

export default function Arrows(props) {

  const move = (e) => {
    let el = e.target.parentElement.classList;
    if (e.keyCode === 38 || el.contains('up')) {
      props.move('forwards')
    }
    if (e.keyCode === 40 || el.contains('down')) {
      props.move('backwards')
    }

  };

  const stopMoving = () => {
    props.stopMoving();
  };

  (function () {
    document.addEventListener('keydown', move);
    document.addEventListener('keyup', stopMoving);
    document.addEventListener('dragend', stopMoving);
  })();

  return (
    <div className="arrows">
      <div className="arrow up">
        <img onMouseDown={move} onMouseUp={stopMoving} src={up} alt="Arrow up" title="Move forward"/>
      </div>
      <div className="arrow down">
        <img onMouseDown={move} onMouseUp={stopMoving} src={down} alt="Arrow down" title="Move backwards"/>
      </div>
    </div>
)
}
