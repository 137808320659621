export const iconUtil = {
  createIcon: function (subevt,defVideoWidth,defVideoHeight) {

    const adminVideoWidth = subevt.icon.createdVideoWidth  ? subevt.icon.createdVideoWidth : defVideoWidth;
    const adminVideoHeight = subevt.icon.createdVideoHeight ? subevt.icon.createdVideoHeight : defVideoHeight;

    let {left, top, scaleX, scaleY} = this.calcIconStyle(subevt.icon, adminVideoWidth, adminVideoHeight);
    return {
      startTime: subevt.startTime,
      endTime: subevt.endTime,
      subevtID: subevt.id,
      subevtName: subevt.name,
      subevtLabel: subevt.label,
      subeventLabelVisibility: subevt.labelVisibility,
      next: subevt.next,
      isExit: subevt.isExit,
      isBack: subevt.isBack,
      directLink: subevt.directLink,
      continueAt: parseFloat(subevt.continueAt),
      introStart: parseFloat(subevt.introStart),
      introEnd: parseFloat(subevt.introEnd),
      eventID: subevt.eventID,
      ...subevt.icon,
      left,
      top,
      scaleX,
      scaleY
    }
  },

  calcIconStyle: function (icon, adminVideoWidth, adminVideoHeight) {
    let currVideoWidth = document.getElementById('3Dstore_html5_api').offsetWidth;
    let currVideoHeight = document.getElementById('3Dstore_html5_api').offsetHeight;
    let left = currVideoWidth * icon.left / adminVideoWidth;
    let top = currVideoHeight * icon.top / adminVideoHeight;
    let scaleX = icon.scaleX * currVideoWidth / adminVideoWidth;
    let scaleY = icon.scaleY * currVideoHeight / adminVideoHeight;
    let transform = `rotate(${icon.angle}deg)`;
    return {left, top, scaleX, scaleY};
  }
}
