export class URLParams {
  constructor() {
    let urlParams = new URLSearchParams(window.location.search);
    let userid = urlParams.get('userid');
    this.urlParams = {
      userid,
      jumpToTime: urlParams.get('jumpToTime')
    };
    window.localStorage.setItem('uid', userid);
  };

  get = param => this.urlParams && this.urlParams[param];
};
