import {getTS} from '../helpers/utils.js';
import {forwardsVideo} from './ForwardsVideo'

export class UserStatistics {
  constructor(isFreeMovement) {
    this.clicks = [];
    this.keyPressed = '';

    document.addEventListener('keydown', this.trackKeyDown);
    if (isFreeMovement)
      document.addEventListener('click', this.trackArrowClicks);
  };

  trackKeyDown = e => {
    if (this.keyPressed === e.key) return;
    this.keyPressed = e.key;
    this.registerUserCommand(this.keyPressed);

  };

  trackArrowClicks = e => {
    if (!e.target.parentElement.classList.contains('arrow')) return;
    let key = e.target.parentElement.classList.contains('up') ? 'mouse-up' : 'mouse-down';
    this.registerUserCommand(key);
  };

  registerUserCommand = key => {
    let {time: videoTime, type: videoType} = forwardsVideo.getCurrData();
    this.clicks.push({
      key,
      ts: getTS(),
      videoTime,
      videoType
    });

  };

  getClicks = () => this.clicks;
  reset = () => {
    this.clicks = [];
    this.keyPressed = '';
  };
}
