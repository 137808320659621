export const responsiveness = {
  hdVideoWidth: 1344,
  hdVideoHeight: 756,
  hdWindowInnerWidth: 1920,
  hdWindowInnerHeight: 1009,
  ratio: 16 / 9,

  calcVideoWidth: function () {
    let videoHeight = window.innerHeight > this.hdWindowInnerHeight ? this.hdWindowInnerHeight : window.innerHeight;
    let videoWidth = videoHeight * this.ratio;
    if(videoWidth > window.innerWidth){
      videoWidth = window.innerWidth
    }
    return videoWidth;
  },

  calcVideoHeight: function () {
    let videoHeight = window.innerHeight > this.hdWindowInnerHeight ? this.hdWindowInnerHeight : window.innerHeight;
    if(videoHeight * this.ratio > window.innerWidth){
      videoHeight = window.innerWidth / this.ratio
    }
    return videoHeight;
  },


  calcArrowsPosition: function (storeEl) {
    let storeElPosition =  storeEl.getBoundingClientRect();
    let arrowsEl = document.querySelector('.arrows');
    let arrowPosition = document.querySelector('.arrow').getBoundingClientRect();
    let arrowWidth = arrowPosition.width;
    let availableSpace = (window.innerWidth - window.innerHeight * this.ratio)/2;
    arrowsEl.style.right = availableSpace + 3 + 'px';

  },

  calcBoardEvtDimensions: function() {
    let width = this.calcVideoWidth() + 'px';
    let height = this.calcVideoHeight() + 'px';
    const HD_HEIGHT = 420;
    let boardEvtBodyHeight = (this.calcVideoHeight() * HD_HEIGHT / this.hdVideoHeight) + 'px';
    return {
      width,
      height,
      boardEvtBodyHeight
    }
  },

  adjustWidthTo: function(elems,width){
    elems.forEach((el) => {
      el.style.width = width + 'px';
    });

  },

  adjustHeightTo: function(elems,height){
    elems.forEach((el) => {
      el.style.height = height + 'px';
    });
  }

}
