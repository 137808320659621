import {iconUtil} from '../../helpers/iconUtil.js';

export const arrowEvents = {
  events: [],
  eventsOnCanvas: [],
  adminVideoWidth:null,
  adminVideoHeight:null,

  addEvent: function(event,adminVideoWidth,adminVideoHeight) {
    this.events.push(event);
    this.adminVideoWidth = adminVideoWidth;
    this.adminVideoHeight = adminVideoHeight;
  },

  update: function(currentTime) {
    for (let i = 0; i < this.events.length; i++) {
      let currEvt = this.events[i];
      let startTime = parseFloat(currEvt.startTime);
      let endTime = parseFloat(currEvt.endTime);
      if (startTime <= currentTime && endTime >= currentTime) {
        if (!this.isEventOnCanvas(currEvt))
          this.putEventOnCanvas(currEvt);
      } else {
        if (this.isEventOnCanvas(currEvt))
          this.removeEventFromCanvas(currEvt);
      }
    }
  },

  isEventOnCanvas: function(evt) {
    return this.eventsOnCanvas.some(canvasEvent => canvasEvent.eventID === evt.id);
  },

  putEventOnCanvas: function(evt) {
    evt.subevents.forEach(subevt => {
      let icon = iconUtil.createIcon(subevt,this.adminVideoWidth,this.adminVideoHeight);
      this.eventsOnCanvas.push(icon);
      window.pubSub.emit('drawIcon', icon);
    });
  },

  removeEventFromCanvas: function(evt) {
    evt.subevents.forEach(subevt => {
      let evtIdx = this.eventsOnCanvas.findIndex(canvasEvent => canvasEvent.eventID === evt.id);
      let canvasEvent = this.eventsOnCanvas[evtIdx];
      window.pubSub.emit('eraseIcon', canvasEvent);
      this.eventsOnCanvas.splice(evtIdx, 1);
    });
  },

  hasStopEvent: function() {
    return this.eventsOnCanvas.length;
  }
}
